class ContactForm {
  constructor () {
    this.init()
  }

  init () {
    this.form = document.getElementById('form')
    this.formPrivacyPolicy = document.getElementById('privacy-policy-checkbox')
    this.formSendButton = document.getElementById('contact-button')
    this.closeNotificationButton = document.getElementById('close-notification')
    if (this.form !== null) {
      this.newForm()
    }
  }

  newForm () {
    // Load Re-captcha V3
    /*
    var script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6LfPCc0ZAAAAAACjSwPyWQDn8Mrg3KTMpFUYjuLe')
    document.getElementsByTagName('head')[0].appendChild(script)
    */
    if ( this.formPrivacyPolicy ) {
      // Make sure the privacy policy value is unchecked
      this.formPrivacyPolicy.checked = false
      this.formPrivacyPolicy.value = 'unchecked'

      // Listen for privacy policy checkbox changes
      this.formPrivacyPolicy.addEventListener('change', this.privacyPolicyCheckboxToogle)
    }
    
    // Prevent form submit
    this.form.addEventListener('submit', function (e) { e.preventDefault() })

    // Form validations and submit
    this.formSendButton.addEventListener('click', this.submitValidateForm)

    // Close notificacion banner
    this.closeNotificationButton.addEventListener('click', this.closeNotificationBanner)

    // Enable submit button
    this.formSendButton.disabled = false
  }

  privacyPolicyCheckboxToogle (privacyPolicyCheckbox) {
    if (privacyPolicyCheckbox.srcElement.value === 'unchecked') {
      privacyPolicyCheckbox.srcElement.value = 'checked'
    } else {
      privacyPolicyCheckbox.srcElement.value = 'unchecked'
    }
  }

  submitValidateForm (evt) {
    // Get the form parameters
    var action = 'contact_form'
    var first_name = document.getElementsByName('first_name')[0]
    var last_name = document.getElementsByName('last_name')[0]
    var company_name = document.getElementsByName('company_name')[0]
    var email = document.getElementsByName('email')[0]
    var privacyPolicy = document.getElementsByName('privacy_policy')[0]
    var successMessage = document.getElementsByName('success_message')[0]
    var errorMessage = document.getElementsByName('error_message')[0]
    var notificationsBanner = document.getElementById('notification-banner')
    var notificationMessage = document.getElementsByClassName('notification-text')[0]

    // Disable and animate the submit button
    evt.srcElement.disabled = true
    evt.srcElement.classList.add('validating')

    // Recaptcha validation
//    grecaptcha.ready(function() { // eslint-disable-line
//      grecaptcha.execute('6LfPCc0ZAAAAAACjSwPyWQDn8Mrg3KTMpFUYjuLe', {action: action}).then(function(token) { // eslint-disable-line

        // Ajax form submit
        var xmlhttp = ajaxReq()
        var url = encodeURI(scripts_ajax_variables.ajax_url) // eslint-disable-line
        var params = '';
        params += 'action=' + action;
        params += '&first_name=' + first_name.value;
        params += '&last_name=' + last_name.value;
        params += '&company_name=' + company_name.value;
        params += '&email=' + email.value;
        params += '&privacy_policy=' + privacyPolicy.value
        //params += '&token=' + token
        xmlhttp.open('POST', url, true) // set true for async, false for sync request
        xmlhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
        xmlhttp.send(params) // or null, if no parameters are passed

        // Validation
        xmlhttp.onreadystatechange = function () {
          if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
            try {
              var data = JSON.parse(xmlhttp.responseText)

              // Hide previous errors
              email.classList.remove('show-error')
              privacyPolicy.classList.remove('show-error')

              if (data.validation === false) {
                // Show errors
                if (data.email) {
                  email.classList.add('show-error')
                  putMessage( email, data.email );
                }
                if (data.privacy_policy) {
                  privacyPolicy.nextElementSibling.classList.add('show-error')
                  putMessage( privacyPolicy, data.privacy_policy );
                }

                // Error notification message
                notificationMessage.innerHTML = errorMessage.value
              } else {
                // Success notification message
                notificationMessage.innerHTML = successMessage.value

                // Clear the form
                evt.srcElement.form.reset()

                // Show notification
                notificationsBanner.classList.add('notification-banner--active')

                document.querySelector('body').classList.remove('--menu-opened');
                document.querySelector( '#contact-form' ).style.display = 'none';
                document.querySelector( '#contact-form-opacity' ).style.display = 'none';          
              }

              // Enable and stop the submit button animation
              evt.srcElement.disabled = false
              evt.srcElement.classList.remove('validating')
            } catch (error) {
              // Show notification
              // notificationsBanner.classList.add('show')
            }
          }
        }
//      })
//    })

    function putMessage( obj, msg ) {
      var id = obj.id + '-msg';
      if ( !document.querySelector( '#' + id ) ) {
        // Create a new element
        var newNode = document.createElement('div');
        newNode.id = id;
        // Insert the new node before the reference node
        if ( null == obj.nextElementSibling ) {
          obj.after( newNode );
        } else {
          if ( 'LABEL' == obj.nextElementSibling.nodeName ) {
            obj.nextElementSibling.after( newNode );
          } else {
            obj.after( newNode );
          }
        }
      }
      document.querySelector( '#' + id ).classList.add( 'error-msg' );
      document.querySelector( '#' + id ).innerHTML = msg;
    }

    function ajaxReq () {
      if (window.XMLHttpRequest) {
        return new XMLHttpRequest() // eslint-disable-line
      } else if (window.ActiveXObject) {
        return new ActiveXObject('Microsoft.XMLHTTP') // eslint-disable-line
      } else {
        console.log('Browser does not support XMLHTTP.')
        return false
      }
    }
  }

  closeNotificationBanner (evt) {
    evt.preventDefault()
    evt.srcElement.parentNode.classList.remove('notification-banner--active')
  }
}

export default ContactForm
